<template>
    <svg :width="width" :height="height" fill="#7383BF" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>

  <!-- <svg :width="width" :height="height" :fill="color" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
    <path style="fill:#7383BF;" d="M53.566,45.283l-9.552-4.776C42.78,39.89,42,38.628,42,37.248V33.5c0.268-0.305,0.576-0.698,0.904-1.162c1.302-1.838,2.286-3.861,2.969-5.984C47.098,25.977,48,24.845,48,23.5v-4c0-0.88-0.391-1.667-1-2.217V11.5c0,0,1.187-9-11-9c-12.188,0-11,9-11,9v5.783c-0.609,0.55-1,1.337-1,2.217v4c0,1.054,0.554,1.981,1.383,2.517C26.382,30.369,29,33.5,29,33.5v3.655c0,1.333-0.728,2.56-1.899,3.198L18.18,45.22C15.603,46.625,14,49.326,14,52.261V55.5h44v-3.043C58,49.419,56.283,46.642,53.566,45.283z"/>
    <path style="fill:#556080;" d="M18.18,45.22l5.946-3.243c-0.034-0.033-0.005-0.043,0.065-0.036l2.91-1.587C28.272,39.715,29,38.489,29,37.155V33.5c0,0-1.062-1.275-2.092-3.323h0c0-0.001-0.001-0.002-0.001-0.003c-0.135-0.268-0.268-0.551-0.399-0.844c-0.018-0.041-0.036-0.08-0.054-0.121c-0.133-0.303-0.263-0.616-0.386-0.944c-0.008-0.021-0.015-0.044-0.023-0.065c-0.108-0.29-0.209-0.589-0.306-0.896c-0.026-0.084-0.052-0.167-0.077-0.251c-0.101-0.338-0.196-0.682-0.278-1.038C24.554,25.481,24,24.554,24,23.5v-4c0-0.88,0.391-1.667,1-2.217v-5.648C23.587,10.539,21.397,9.5,18,9.5c-9.563,0-10,8-10,8v4.995c-0.526,0.475-1,1.154-1,1.914v3.455c0,0.911,0.479,1.711,1.194,2.174c0.863,3.758,3.76,6.463,3.76,6.463v3.157c0,1.151-0.629,2.211-1.64,2.762L3.61,46.622C1.385,47.836,0,50.168,0,52.703V55.5h14v-3.239C14,49.326,15.603,46.625,18.18,45.22z"/>
  </svg> -->
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: '#0f0101'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>

<template>
  <div class="rounded h-full bg-white mt-20">
      <div  class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 row-span-1 gap-y-8">
        <div class="relative  hover:shadow-xl transition delay-100" >
           <div class="h-28 w-full rounded-lg bg-white cust_card">
             <div class="my-auto  pr-10 pt-6  text-right">
               <div class="heading-1 text-text2 font-semibold">Staff</div>
             </div>
             <div class="divider mt-3 mb-2"></div>
             <div class="justify-end flex heading-5 pr-10 text-primary"><span class="cursor-pointer" @click="$router.push({ name: 'StaffListing' })">Staff</span></div>
           </div>
           <div class="absolute top-0 left-6 -mt-12 h-24 w-28 rounded-md bg-blue-200 shadow-md inline-flex">
             <div class=" flex justify-center items-center w-32 ">
               <usersSvg color="white" :width="'70px'" :height="'70px'"/>
             </div>
           </div>
        </div>
        <div class="relative  hover:shadow-xl transition delay-100" >
           <div class="h-28 w-full rounded-lg bg-white cust_card">
             <div class="my-auto  pr-10 pt-6  text-right">
               <div class="heading-1 text-text2 font-semibold">Configuration</div>
             </div>
             <div class="divider mt-3 mb-2"></div>
             <div class="justify-end flex heading-5 pr-10 text-primary"><span class="cursor-pointer" @click="$router.push({ name: 'GlobalSetting' })">Configuration</span></div>
           </div>
           <div class="absolute top-0 left-6 -mt-12 h-24 w-28 rounded-md bg-blue-200 shadow-md inline-flex">
             <div class=" flex justify-center items-center w-32 ">
               <setting color="white" :width="'55px'" :height="'55px'"/>
             </div>
           </div>
        </div>
        <div class="relative  hover:shadow-xl transition delay-100" >
           <div class="h-28 w-full rounded-lg bg-white cust_card">
             <div class="my-auto  pr-10 pt-6  text-right">
               <div class="heading-1 text-text2 font-semibold">Text Templates</div>
             </div>
             <div class="divider mt-3 mb-2"></div>
             <div class="justify-end flex heading-5 pr-10 text-primary"><span class="cursor-pointer" @click="$router.push({ name: 'Template' })">Text Templates</span></div>
           </div>
           <div class="absolute top-0 left-6 -mt-12 h-24 w-28 rounded-md bg-blue-200 shadow-md inline-flex">
             <div class=" flex justify-center items-center w-32 ">
               <template1 color="white" :width="'55px'" :height="'55px'"/>
             </div>
           </div>
        </div>
      </div>
  </div>
</template>
<script>
import usersSvg from '@/assets/images/svg/usersSvg.vue';
import setting from '@/assets/images/svg/setting.vue';
import template1 from '@/assets/images/svg/template.vue';
export default {
  components: {
    usersSvg,
    setting,
    template1
  },
  data () {
    return {
      userDetail: {},
      settingOptions: [],
      cardHeight: window.innerHeight - 94
    }
  },
  mounted () {
    document.title = 'Setting Dashboard'
    let userData = localStorage.getItem('employeeData')
    this.userDetail = JSON.parse(userData)
  },
  methods: {
  }
}
</script>

<style scoped>
.card-content {
  background-color: transparent;
  padding: 0rem;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}
.sheets {
  border-radius: 10%;
  width: 70px !important;
  height: 67px !important;
  justify-content: center;
  display: flex;
  margin-top: -40px;
  border-color: #26c6da;
}
.hoverableCard:hover {
  box-shadow: 1px 5px 5px 1px rgba(38, 38, 38, 0.2);
  top: 0px;
  border: 0px solid #cccccc;
  /* background-color: rgb(244, 244, 244); */
  /* box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2); */
  /* transition:all 0.5s; */
  /* border: 0px solid #cccccc; */
}
.cardAbsolute {
  position: absolute;
  left: 20px;
  top: -30px;
  border-radius: 8%;
  width: 80px !important;
  height: 80px !important;
  text-align: center;
  justify-content: center;
  display: flex;
}
.marginTop {
  margin-top: 30px;
}

.card_icon{
    position: absolute;
    left: 20px;
    top: -30px;
    border-radius: 8%;
    width: 100px!important;
    height: 100px!important;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
</style>
